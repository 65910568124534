import { refreshZones, getImageMap, getExtent } from './map'
import * as rpt from './report'

const complex_url = import.meta.env.VITE_COMPLEX_URL
const zone_url = import.meta.env.VITE_ZONE_URL
const admissibility_url = import.meta.env.VITE_ADMISSIBILITY_URL
let complex_level1, complex_level2, complex_level3
let complex_prop = {}
let zone_data
let complex_selected, complex_selected_global, complex_selected_specific
let admissibility

/**
 * 
 * Mobile device ? (according window width)
 */
function isMobile() {
  return $(window).width() <= 600
}

/**
 * 
 * Normalize width according window width
 */
function normalizeWidth(width, offset=8) {
  return isMobile() ? $(window).width() - offset : width
}

/**
 * 
 * Load complexes select and save its properties into complex_prop
 */
function loadComplexLevel1() {
  const url = import.meta.env.VITE_COMPLEX_LEVEL1_URL
  const promise = new Promise((resolve, reject) => {
    fetch(url)
    .then(response => response.json())
    .then(json => {
      complex_level1 = json
      json.forEach(item => {
        const option = $('<option>').attr('value', item.code).html(item.text)
        $('#complex-select-level1').append(option)
      })
      resolve()
    })
  })
  return promise
}

function loadComplexLevel2(code) {
  const url = import.meta.env.VITE_COMPLEX_LEVEL2_URL
  const promise = new Promise((resolve, reject) => {
    fetch(url)
    .then(response => response.json())
    .then(json => {
      complex_level2 = json
      cleanComplexLevel2()
      // cleanComplexLevel3()
      loadComplexLevel3(code)
      if (json[code]) {
        // filter by code
        json[code].forEach(item => {
          const option = $('<option>').attr('value', item.code).html(item.text)
          $('#complex-select-level2').append(option)
        })
      } else {
        // load all items
        Object.keys(json).forEach(code => {
          json[code].forEach(item => {
            const option = $('<option>').attr('value', item.code).html(item.text)
            $('#complex-select-level2').append(option)
          })
        })
      }
      resolve()
    })
  })
  return promise
}

function loadComplexLevel3(code) {
  const url = import.meta.env.VITE_COMPLEX_LEVEL3_URL
  const promise = new Promise((resolve, reject) => {
    fetch(url)
    .then(response => response.json())
    .then(json => {
      complex_level3 = json
      cleanComplexLevel3()
      if (json[code]) {
        // filter by code
        json[code].forEach(item => {
          // saves into complex variable
          complex_prop[item.code] = item.propiedades
          complex_prop[item.code].nombre = item.text
          // create the select option
          const option = $('<option>')
            .attr('value', item.code)
            .attr('geojson', item.geojson)
            .html(item.text)
          $('#complex-select-level3').append(option)
        })
      } else {
        // load all items or filtered by level 1
        let levels = []
        if (typeof code !== 'undefined') {
          // filtered by level 1
          Object.keys(json).forEach(function (item) {
            if (item.startsWith(code)) {
              this.push(item)
            }
          }, levels)
        } else {
          // load all
          levels = Object.keys(json)
        }
        levels.forEach(code3 => {
          json[code3].forEach(item => {
            // saves into complex variable
            complex_prop[item.code] = item.propiedades
            complex_prop[item.code].nombre = item.text
            // create the select option
            const option = $('<option>')
              .attr('value', item.code)
              .attr('geojson', item.geojson)
              .html(item.text)
            $('#complex-select-level3').append(option)
          })
        })
      }
      resolve()
    })
  })
  return promise
}

/**
 * 
 * Load zone data
 */
function loadZoneData() {
  const promise = new Promise((resolve, reject) => {
    fetch(zone_url)
    .then(response => response.json())
    .then(json => {
      resolve(json)
    })
  })
  return promise
}

/**
 * 
 * Load admissibility
 */
function loadAdmissibility() {
  const promise = new Promise((resolve, reject) => {
    fetch(admissibility_url)
    .then(response => response.json())
    .then(json => {
      resolve(json)
    })
  })
  return promise
}

/**
 * 
 * Render complex data
 */
function renderComplexData(code) {
  const prop = [
    'Definición',
    'Uso',
    'Rango de admisibilidad'
  ]
  const prop_text = {
    'Definición': 'Definición',
    'Uso': 'Uso específico',
    'Rango de admisibilidad': 'Rango de admisibilidad'
  }
  const metadata = $('#complex-metadata')
  metadata.empty()
  for (const [key, value] of Object.entries(complex_prop[code])) {
    if (prop.includes(key)) {
      const p = $(`<p><span style="font-weight:bold">${prop_text[key]}</span>: <span>${value}</span></p>`)
      metadata.append(p)
    }
  }
  const adm = $('#complex-admisibilidad')
  adm.empty()
  if (admissibility[complex_prop[code]["Rango de admisibilidad"]]) {
    adm.append(admissibility[complex_prop[code]["Rango de admisibilidad"]].desc)
  }
}

/**
 * 
 * Render zone data
 */
// function renderActs(a, level=1, col_list_prev=[]) {
//   let div_list = []
//   const class_name = `actos-${level}`
//   let exist_condicionantes = false
//   a.forEach((item, index) => {
//     let col_list = []

//     // level or act name
//     const level_element = $(`<div class="${class_name}">${item.nombre}</div>`)
//     if (item.definicion) {
//       level_element.attr('data-toggle', 'tooltip')
//       .attr('data-bs-html', true)
//       .attr('data-bs-placement', 'left')
//       .attr('title', item.definicion)
//     }
//     col_list.push(level_element)

//     if (!item.actos) {
//       // add previous levels
//       if (index == 0) {
//         col_list = col_list_prev.concat(col_list)
//         if (level == 2) {
//           col_list.push($('<div class="actos-3"></div'))
//         } else {
//           for (let i = col_list_prev.length + 1; i < 3; i++) {
//             col_list.push($(`<div class="actos-${i}"></div>`))
//           }
//         }
//       } else {
//         if (level == 2) {
//           col_list.unshift($('<div class="actos-1"></div'))
//           col_list.push($('<div class="actos-3"></div'))
//         } else {
//           for (let i = 2; i > 0; i--) {
//             col_list.unshift($(`<div class="actos-${i}"></div>`))
//           }
//         }
//       }
//       // condicionantes
//       let condicionantes = ''
//       if (item.condicionantes) {
//           let cond_sep = ''
//           item.condicionantes.forEach(cond => {
//               condicionantes += `${cond_sep}${cond.orden}`
//               cond_sep = '/'
//           })
//       }
//       if (condicionantes != '') {
//         exist_condicionantes = true
//       }
//       col_list.push($(`<div class="actos-4">${condicionantes}</div>`))
//       // permitido ?
//       let td_permitido = $(`<div class="actos-5"></div>`)
//       if (item.permitido) {
//         td_permitido.text('Sí')
//         td_permitido.addClass('permitido')
//       } else {
//         td_permitido.text('No')
//       }
//       col_list.push(td_permitido)
//       if (div_list.length) {
//         // separador
//         div_list.push($('<hr>'))
//       }
//       div_list = div_list.concat(col_list)
//     } else {
//       // acts
//       let children = renderActs(item.actos, level + 1, col_list_prev.concat(col_list))
//       if (div_list.length) {
//         // separador
//         div_list.push($('<hr>'))
//       }
//       // div_list = div_list.concat(children)
//       div_list = div_list.concat(children.list)
//       if (!exist_condicionantes) {
//         exist_condicionantes = children.exist_condicionantes
//       }
//     }
//   })
//   // return div_list
//   return {
//     exist_condicionantes: exist_condicionantes,
//     list: div_list
//   }
// }
function renderActs(a, level=1, col_list_prev=[]) {
  let div_list = []
  const class_name = `actos-${level}`
  let exist_condicionantes = false
  a.forEach((item, index) => {
    let col_list = []

    // level or act name
    const level_element = $(`<td class="${class_name}">${item.nombre}</td>`)
    if (item.definicion) {
      level_element.attr('data-toggle', 'tooltip')
      .attr('data-bs-html', true)
      .attr('data-bs-placement', 'left')
      .attr('title', item.definicion)
    }
    col_list.push(level_element)

    if (!item.actos) {
      // add previous levels
      if (index == 0) {
        col_list = col_list_prev.concat(col_list)
        if (level == 2) {
          col_list.push($(`<td class="actos-3"></td>`))
        } else {
          for (let i = col_list_prev.length + 1; i < 3; i++) {
            col_list.push($(`<td class="actos-${i}"></td>`))
          }
        }
      } else {
        if (level == 2) {
          col_list.unshift($(`<td class="actos-1"></td>`))
          col_list.push($(`<td class="actos-3"></td>`))
        } else {
          for (let i = 2; i > 0; i--) {
            col_list.unshift($(`<td class="actos-${i}"></td>`))
          }
        }
      }
      // condicionantes
      let condicionantes = ''
      if (item.condicionantes) {
          let cond_sep = ''
          item.condicionantes.forEach(cond => {
              condicionantes += `${cond_sep}${cond.orden}`
              cond_sep = '/'
          })
      }
      if (condicionantes != '') {
        exist_condicionantes = true
      }
      col_list.push($(`<td class="actos-4">${condicionantes}</td>`))
      // permitido ?
      let td_permitido = $(`<td class="actos-5"></td>`)
      if (item.permitido) {
        td_permitido.html('<div>Sí</div>')
        td_permitido.children().first().addClass('permitido')
      } else {
        td_permitido.html('<div>No</div>')
      }
      col_list.push(td_permitido)
      div_list = div_list.concat(col_list)
    } else {
      // acts
      let children = renderActs(item.actos, level + 1, col_list_prev.concat(col_list))
      div_list = div_list.concat(children.list)
      if (!exist_condicionantes) {
        exist_condicionantes = children.exist_condicionantes
      }
    }
  })
  return {
    exist_condicionantes: exist_condicionantes,
    list: div_list
  }
}

function renderZoneData(zone) {
  if (!complex_selected) {
    return false
  }
  $('#collapseInfo').empty()

  // report data initialize
  rpt.initialize()

  const data = zone_data[complex_selected][zone]
  const div_id = 'zone-dialog'
  if (!data) {
    $(`#${div_id}`).remove()
    return false
  }
  const template = document.querySelector('#tpl-zona')
  const clone = template.content.cloneNode(true)

  // complejo
  let p = clone.querySelector('p')
  p.textContent = complex_prop[complex_selected].nombre

  // zona
  let tbl = clone.querySelector('#zona')
  let tpl = document.querySelector('#tpl-row-2')
  let row = tpl.content.cloneNode(true)
  let td = row.querySelectorAll('td')
  if (data['codigo_ui']) {
    // algunas zonas se subdividieron por motivos prácticos
    td[0].textContent = data['codigo_ui']
  } else {
    td[0].textContent = zone
  }
  // tooltip de zona
  let tooltip_autoshow
  if (data['tooltip']) {
    $(td[0]).attr('data-toggle', 'tooltip')
    $(td[0]).attr('title', data['tooltip'])
    tooltip_autoshow = $(td[0])
  }
  td[1].textContent = data['Alcance - carácter']
  tbl.querySelector('tbody').append(row)

  // condicionantes a la autorización del complejo
  tbl = clone.querySelector('#condicionantes-complejo')
  if (data['Condicionantes']) {
    tpl = document.querySelector('#tpl-row-2')
    data['Condicionantes'].forEach(item => {
      row = tpl.content.cloneNode(true)
      td = row.querySelectorAll('td')
      td[0].textContent = item['codigo']
      td[1].textContent = item['descripcion']

      // report data
      rpt.add_complex_condition(item['codigo'], item['descripcion'])

      tbl.querySelector('tbody').append(row)  
    })
  } else {
    clone.removeChild(tbl)
  }

  // actos de ejecución
  const tr_acts = renderActs(data['Actos de ejecución'])
  let act_tbody = clone.querySelector('#actos-tbody')
  let td_count = 0
  let act_tr = $('<tr>')
  tr_acts.list.forEach(ele => {
    ++td_count
    act_tr.append(ele[0].cloneNode(true))
    if (td_count == 5) {
      td_count = 0
      $(act_tbody).append(act_tr)
      act_tr = $('<tr>')
    }
  })

  // condiciones generales
  if (complex_prop[complex_selected]['Condiciones generales']) {
    tbl = clone.querySelector('#condicionantes-generales')
    tpl = document.querySelector('#tpl-row-1')
    row = tpl.content.cloneNode(true)
    row.querySelector('td').innerHTML = complex_prop[complex_selected]['Condiciones generales']
    tbl.querySelector('tbody').append(row)
  } else {
    clone.removeChild(clone.querySelector('#condicionantes-generales'))
  }

  // condicionantes a los actos de ejecución
  if (tr_acts.exist_condicionantes) {
    if (complex_prop[complex_selected]['Condicionantes a los actos de ejecución']) {
      tbl = clone.querySelector('#condicionantes-actos')
      tpl = document.querySelector('#tpl-row-1')
      row = tpl.content.cloneNode(true)
      row.querySelector('td').innerHTML = complex_prop[complex_selected]['Condicionantes a los actos de ejecución']
      tbl.querySelector('tbody').append(row)
    } else {
      clone.removeChild(clone.querySelector('#condicionantes-actos'))
    }
  } else {
    clone.removeChild(clone.querySelector('#condicionantes-actos'))
  }

  // report data
  rpt.set_complex({
      'global': complex_selected_global,
      'specific': complex_selected_specific,
      'complex': complex_selected,
      'admissibility': {
        'range': complex_prop[complex_selected]['Rango de admisibilidad'],
        'definition': admissibility[complex_prop[complex_selected]["Rango de admisibilidad"]] ?
          admissibility[complex_prop[complex_selected]['Rango de admisibilidad']].desc : ''
      }
    },
    complex_prop[complex_selected], tr_acts.exist_condicionantes)
  rpt.set_zone(zone, zone_data[complex_selected][zone])
  rpt.set_execution_acts(data['Actos de ejecución'])

  // observaciones
  if (complex_prop[complex_selected]['Observaciones']) {
    tbl = clone.querySelector('#observaciones')
    tpl = document.querySelector('#tpl-row-1')
    row = tpl.content.cloneNode(true)
    row.querySelector('td').innerHTML = complex_prop[complex_selected]['Observaciones']
    tbl.querySelector('tbody').append(row)
  } else {
    clone.removeChild(clone.querySelector('#observaciones'))
  }

  $('#collapseInfo').append(clone).addClass('show')
  $('#collapseComplejo').removeClass('show')

  $('#collapseInfo').append(
    '<div class="info-source">Fuente de la información: <a href="https://plan-insular.idegrancanaria.es/?ref=PIOGC_2022_ADAPDIR" target="_blank">Plan Insular de Ordenación de Gran Canaria Aprobación Definitiva 2022</a></div>')

  // acts divs events
  $('#actos>tbody>tr').on('click', onActSelect)

  setTooltips()

  if (tooltip_autoshow) {
    tooltip_autoshow.tooltip('show')
    setTimeout(() => {
      tooltip_autoshow.tooltip('hide')
    }, 3000)
  }

  showPrintBtn(true)

}

/**
 * 
 * Complex select event
 * @param {*} event 
 * @param {*} ui 
 */
function onComplexSelect(e) {

  showPrintBtn(false)

  cleanComplex()
  
  complex_selected = e.target.value
  if (e.target.selectedIndex > 0) {
    complex_selected_global = $('#complex-select-level1>option[value="' + complex_selected.match(/((\d)\.\d)\.\d/)[2] +'"]').text()
    complex_selected_specific = $('#complex-select-level2>option[value="' + complex_selected.match(/((\d)\.\d)\.\d/)[1] +'"]').text()
  }

  if (!e.target.value) {
    $('#complex-metadata').empty()
  } else if (e.target.selectedIndex > 0) {
    // show metadata
    renderComplexData(complex_selected)
  }

  // refresh styles
  const zones_url = $(e.target.selectedOptions).attr('geojson')
  if (zones_url) {
    refreshZones(`/data/${zones_url}`)
  } else {
    refreshZones()
  }
}

/**
 * 
 * Act select event
 * @param {*} event 
 * @param {*} ui 
 */
function onActSelect(e) {
  const ele = $(e.target.parentElement)
  if (ele.hasClass('act-selected')) {
    ele.removeClass('act-selected')
  } else {
    ele.addClass('act-selected')
  }
}

function getSelectedActs() {
  let selected = []
  $('#actos>tbody>tr.act-selected').each((index, tr) => {
    // console.log('fila', $('#actos>tbody>tr').index(tr))
    selected.push($('#actos>tbody>tr').index(tr))
  })
  return selected
}

/**
 * Legend
 */
function showLegend() {
  const div_id = 'legend-div'
  let legend_dialog = $(`#${div_id}`)
  if (!legend_dialog.length) {
    legend_dialog = $(`<div id="${div_id}"></div>`)
  }
  legend_dialog.dialog({
    autoOpen: false,
    title: 'Leyenda',
    position: {my: 'left bottom', at: 'left bottom', of: window},
    width: normalizeWidth(500),
    resizable: false,
    open: function (e, ui) {
      $(e.target).load('/data/leyenda.html')
    },
    close: function(e) {
      $(e.target).dialog('destroy').remove()
    }
  })
  if (legend_dialog.dialog('isOpen')) {
    legend_dialog.dialog('close')
  } else {
    legend_dialog.dialog('open')
  }
}

function trackEvent(opts) {
  try {
    gtag(
      'event', opts.category,
      {
        'event_item': opts.action,
        'event_label': opts.opt_label,
        'event_value': opts.opt_value
      }
    )
  } catch (ex) {
    console.log(ex)
  }
}

/**
 * Tooltips
 */
function setTooltips() {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })
}

/**
 * Clean functions
 */
function cleanComplex() {
  $('#complex-metadata').empty()
  $('#complex-admisibilidad').empty()
  $('#collapseInfo').empty()
}

function cleanComplexLevel2() {
  $('#complex-select-level2').empty()
  $('#complex-select-level2').append('<option selected>Seleccione uso específico...</option>')
}

function cleanComplexLevel3() {
  $('#complex-select-level3').empty()
  $('#complex-select-level3').append('<option selected>Seleccione uso complejo...</option>')
}

/**
 * Print
 */
function showPrintBtn(flag) {
  if (flag) {
    $('#print-pdf').removeClass('d-none')
  } else {
    $('#print-pdf').addClass('d-none')
  }
}

/**
 * Events
 */
function resize() {
  $('#collapseInfo').height($(window).height() - 165)
  $('#collapseInfo').width($('#titulo').width() - 20)
}

function events() {

  // complex level 1
  $('#complex-select-level1').on('change', e => {
    showPrintBtn(false)
    cleanComplex()
    if (e.target.selectedIndex > 0) {
      loadComplexLevel2(e.target.value)
    } else {
      loadComplexLevel2()
    }
  })

  // complex level 2
  $('#complex-select-level2').on('change', e => {
    showPrintBtn(false)
    cleanComplex()
    if (e.target.selectedIndex > 0) {
      loadComplexLevel3(e.target.value)
    } else {
      if ($('#complex-select-level1').index() > 0) {
        loadComplexLevel3($('#complex-select-level1').val())
      } else {
        loadComplexLevel3()
      }
    }
  })

  // complex level 3
  $(`#complex-select-level3`).on('change', onComplexSelect)

  // legend
  $('#legend-btn').on('click', () => {
    showLegend()
  })

  // report
  $('#print-pdf').on('click', function(event) {
    trackEvent({
      category: 'Generar PDF',
      // action: 'Impresión'
    })
    event.preventDefault()
    getImageMap(function(blob) {
      rpt.set_image(blob, getExtent())
      rpt.create_report(getSelectedActs())
    })
  })

  $(window).on('resize', () => {
    resize()
  })

}

/**
 * Initialize UI
 */
function init() {
  events()

  resize()

  // load global uses
  loadComplexLevel1()
  loadComplexLevel2()
  loadComplexLevel3()

  // load zone data
  loadZoneData().then(json => {
    zone_data = json
    // load admissibility
    loadAdmissibility()
    .then(json => {
      admissibility = json
    })
  })

}

export { init, renderZoneData, complex_selected as complexCode }