import './style.css'
import { init as initMap } from './map'
import * as ui from './ui'

let crs = '4326'
try {
  crs = document.location.search.match(/crs=([^&]+)/i)[1]
} catch (e) {
  crs = '32628'
}
initMap(crs)

ui.init()