let rpt_data
let rpt_image
const iframe_id = 'pdf-iframe-id'

const rpt_options = {
  format: 'A4',
  orientation: 'portrait',
  border: '10mm',
  header: {
    height: '45mm',
    contents: '<div style="text-align: center;">Régimen de Usos Específicos y Condiciones Mínimas de Implantación en Suelo Rústico</div>'
  },
  footer: {
    height: '28mm',
    contents: {
      first: 'Cover page',
      2: 'Second page', // Any page number is working. 1-based index
      default: '<span style="color: #444;">{{page}}</span>/<span>{{pages}}</span>', // fallback value
      last: 'Last Page'
    }
  }
}

// report data functions
function initialize() {
  rpt_data = {
    'extent': [],
    'complex': {
      'code': '',
      'global': '',
      'specific': '',
      'use': '',
      'name': '',
      'definition': '',
      'auth-conditions': []
    },
    'admissibility': {
      'range': '',
      'definition': ''
    },
    'zone': {
      'code': '',
      'scope': ''
    },
    'execution-acts': {},
    'acts-conditions': '',
    'general-conditions': '',
    'observations': ''
  }
}

function set_complex(complex, data) {
  rpt_data.complex.global = complex['global']
  rpt_data.complex.specific = complex['specific']
  rpt_data.complex.code = complex['complex']
  rpt_data.complex.use = data['Uso']
  rpt_data.complex.name = data['nombre']
  rpt_data.complex.definition = data['Definición']
  rpt_data['acts-conditions'] = data['Condicionantes a los actos de ejecución']
  rpt_data['general-conditions'] = data['Condiciones generales']
  rpt_data.observations = data['Observaciones']
  rpt_data.admissibility.range = complex.admissibility.range
  rpt_data.admissibility.definition = complex.admissibility.definition
}

function add_complex_condition(code, description) {
  rpt_data['complex']['auth-conditions'].push({
    'code': code,
    'description': description
  })
}

function set_zone(code, data) {
  rpt_data['zone']['code'] = code
  rpt_data['zone']['scope'] = data['Alcance - carácter']
}

function set_execution_acts(data) {
  rpt_data['execution-acts'] = data
}

function get_data() {
  return rpt_data
}

function set_image(blob, extent) {
  rpt_data.extent = extent
  rpt_image = blob
}

function createJSONData(acts_sel) {
  let js_data = {
    zone: {
      code: '',
      scope: ''
    },
    complex: {
      global: '',
      specific: '',
      name: '',
      definition: ''
    },
    use: '',
    admissibility: {
      range: '',
      definition: ''
    },
    overview: {
      map: '',
      width: 0,
      extent: []
    },
    conditioning: [],
    'acts-conditions': '',
    'general-conditions': '',
    observations: '',
    'execution-acts': [] // five items each one
  }
  
  const isActIncluded = function(index) {
    let is_included = true
    if (acts_sel.length) {
      is_included = acts_sel.includes(index)
    }
    return is_included
  }

  return new Promise((resolve, reject) => {
    try {
      let html
      const width = parseInt($('#collapseInfo').width())

      js_data.complex = {
        global: rpt_data.complex.global,
        specific: rpt_data.complex.specific,
        name: rpt_data.complex.name,
        definition: rpt_data.complex.definition
      }
    
      js_data.zone = {
        code: rpt_data.zone.code,
        scope: rpt_data.zone.scope
      }
    
      js_data.use = rpt_data.complex.use
    
      js_data.admissibility = {
        range: rpt_data.admissibility.range,
        definition: rpt_data.admissibility.definition
      }
    
      js_data.overview = {
        map: rpt_image.replace('data:image/png;base64,', ''),
        width: width,
        extent: rpt_data.extent
      }
    
      if (rpt_data.complex['auth-conditions'].length > 0) {
        rpt_data.complex['auth-conditions'].forEach(function (item) {
          js_data.conditioning.push({
            code: item.code,
            description: item.description
          })
        })
      }
    
      if ((rpt_data['acts-conditions'] || '') != '') {
        js_data['acts-conditions'] = rpt_data['acts-conditions']
      }
    
      if ((rpt_data['general-conditions'] || '') != '') {
        js_data['general-conditions'] = rpt_data['general-conditions']
      }
    
      if ((rpt_data['observations'] || '') != '') {
        js_data.observations = rpt_data.observations
      }
    
      // actos de ejecución
      const processConditions = function (act) {
        let conditions = ''
        let sep = ''
        if (act.condicionantes) {
          act.condicionantes.forEach(cond => {
            conditions += sep + cond.orden
            sep = '/'
          })
        }
        return conditions
      }
    
      let row_index = -1
      let tmp_data
      rpt_data['execution-acts'].forEach((act1, index1) => {
        if (act1.actos) {
          act1.actos.forEach((act2, index2) => {
            if (act2.actos) {
              act2.actos.forEach((act3, index3) => {
                ++row_index
                if (isActIncluded(row_index)) {
                  tmp_data = {}
                  if (index3 == 0) {
                    tmp_data.act1 = act1.nombre
                  } else {
                    tmp_data.act1 = ''
                  }
                  if (index3 == 0) {
                    tmp_data.act2 = act2.nombre
                  } else {
                    tmp_data.act2 = ''
                  }
                  const condicionantes = processConditions(act3)
                  tmp_data.act3 = act3.nombre
                  tmp_data.cond = condicionantes
                  tmp_data.allow = act3.permitido ? 'Sí' : 'No'
                  js_data['execution-acts'].push(tmp_data)
                }
              })
            } else {
              ++row_index
              if (isActIncluded(row_index)) {
                tmp_data = {}
                if (index2 == 0) {
                  tmp_data.act1 = act1.nombre
                } else {
                  tmp_data.act1 = ''
                }
                const condicionantes = processConditions(act2)
                tmp_data.act2 = act2.nombre
                tmp_data.act3 = ''
                tmp_data.cond = condicionantes
                tmp_data.allow = act2.permitido ? 'Sí' : 'No'
                js_data['execution-acts'].push(tmp_data)
              }
            }
          })
        } else {
          ++row_index
          if (isActIncluded(row_index)) {
            const condicionantes = processConditions(act1)
            js_data['execution-acts'].push({
              act1: act1.nombre,
              act2: '',
              act3: '',
              cond: condicionantes,
              allow: act1.permitido ? 'Sí' : 'No'
            })
          }
        }
      })

      resolve(js_data)
    } catch (ex) {
      reject(ex)
    }
  })
}

// report functions
function create_report(acts_sel) {
  createJSONData(acts_sel)
  .then(data => {
    $('.modal').show()
    $.ajax({
      url: document.location.host.match(/^(localhost).*/) ? "/js_proxy" : "/js_proxy.php",
      xhrFields:{
          responseType: "blob"
      },
      data: {
          "reportUnitUri": import.meta.env.VITE_REPORT_UNIT_URI,
          "jsonString": JSON.stringify(data)
      },
      method: "POST",
      success: function(data) {
          $('.modal').hide()
          var link = document.createElement("a")
          link.target = "_blank"
          link.style.display = "none"
          link.href = URL.createObjectURL(data)
          document.body.appendChild(link)
          link.click()
          setTimeout(function() {
              document.body.removeChild(link)
              URL.revokeObjectURL(link.href)
          }, 500)
      },
      error: function(xhr, textStatus, errorThrown) {
          $('.modal').hide()
          console.log(textStatus)
      }
    })
  })
}

export {
  initialize,
  set_complex,
  add_complex_condition,
  set_zone,
  set_execution_acts,
  create_report,
  set_image,
  get_data
}