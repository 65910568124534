import { Feature } from 'ol'
import { Control } from 'ol/control.js';
import { Fill, Stroke, Text, Circle, Style } from 'ol/style.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import { Draw, Modify, Select } from 'ol/interaction.js'
import KML from 'ol/format/KML.js';
import { Point } from 'ol/geom'

/*
 * Custom control: drawing
 */
class DrawingControl extends Control {
	/**
	 * @param {Object} [opt_options] Control options.
	 */

	constructor(opt_options) {

		const options = opt_options || {}

		const btn_trash = document.createElement('button')
		btn_trash.title = 'Eliminar formas'
		btn_trash.classList.add('drawing-btn')
		btn_trash.classList.add('drawing-trash')

		const btn_add_point = document.createElement('button')
		btn_add_point.title = 'Agregar punto'
		btn_add_point.classList.add('drawing-btn')
		btn_add_point.classList.add('drawing-point')
		btn_add_point.classList.add('inactive')

		const btn_add_line = document.createElement('button')
		btn_add_line.title = 'Agregar línea'
		btn_add_line.classList.add('drawing-btn')
		btn_add_line.classList.add('drawing-line')
		btn_add_line.classList.add('inactive')

		const btn_add_polygon = document.createElement('button')
		btn_add_polygon.title = 'Agregar polígono'
		btn_add_polygon.classList.add('drawing-btn')
		btn_add_polygon.classList.add('drawing-polygon')
		btn_add_polygon.classList.add('inactive')

		const btn_label = document.createElement('button')
		btn_label.title = 'Etiquetar forma'
		btn_label.disabled = true
		btn_label.classList.add('drawing-btn')
		btn_label.classList.add('drawing-label')

		const btn_select = document.createElement('button')
		btn_select.title = 'Seleccionar formas'
		btn_select.classList.add('drawing-btn')
		btn_select.classList.add('drawing-select')
		btn_select.classList.add('inactive')

		const element = document.createElement('div')
		element.className = 'drawing-control ol-unselectable ol-control'
		element.appendChild(btn_trash)
		element.appendChild(btn_add_point)
		element.appendChild(btn_add_line)
		element.appendChild(btn_add_polygon)
		element.appendChild(btn_label)
		element.appendChild(btn_select)

		super({
			element: element,
			target: options.target,
		})

		btn_trash.addEventListener('click', this.remove.bind(this), false)
		btn_add_point.addEventListener('click', this.add.bind(this, btn_add_point, 'Point'), false)
		btn_add_line.addEventListener('click', this.add.bind(this, btn_add_line, 'LineString'), false)
		btn_add_polygon.addEventListener('click', this.add.bind(this, btn_add_polygon, 'Polygon'), false)
		btn_label.addEventListener('click', this.label.bind(this), false)
		btn_select.addEventListener('click', this.select.bind(this), false)

		this.btn_select = btn_select
		this.btn_label = btn_label

		this.draw_layer = undefined
		this.draw_source = undefined
		this.draw = undefined
		this.draw_modify = undefined
		this.draw_select = undefined
		this.draw_shape_type = undefined
		this.draw_zIndex = 2
		this.draw_shape_type = undefined
	}

	draw_vector_style = new Style({
		fill: new Fill({
			color: 'rgba(255, 255, 255, 0.2)',
		}),
		stroke: new Stroke({
			color: '#ffcc33',
			width: 2,
		}),
		image: new Circle({
			radius: 7,
			fill: new Fill({
				color: 'rgba(255, 204, 51, 0.2)'
			}),
			stroke: new Stroke({
				color: '#ffcc33',
				width: 2
			})
		})
	})

	draw_label_style = new Style({
		text: new Text({
			font: '13px Calibri,sans-serif',
			fill: new Fill({
				color: '#000',
			}),
			stroke: new Stroke({
				color: '#fff',
				width: 4,
			}),
		}),
	})

	init() {
		if (typeof this.draw_source == 'undefined') {
			this.draw_source = new VectorSource()

			const me = this
			this.draw_layer = new VectorLayer({
				name: 'draw',
				source: this.draw_source,
				style: function (feature) {
					const text_style_clone = me.draw_label_style.clone()
					if (feature.get('label')) {
						text_style_clone
							.getText()
							.setText([
								`${feature.get('label')}`,
								'bold 13px Calibri,sans-serif',
							])
					}
					return [me.draw_vector_style, text_style_clone]
				},
				zIndex: this.draw_zIndex
			})

			this.getMap().addLayer(this.draw_layer)

			this.draw_modify = new Modify({ source: this.draw_source })
			this.getMap().addInteraction(this.draw_modify)
		}
	}

	change(type) {
		this.getMap().removeInteraction(this.draw)

		this.draw = new Draw({
			source: this.draw_source,
			type: type,
			stopClick: true
		})

		this.getMap().addInteraction(this.draw)
	}

	end() {
		this.draw_shape_type = void (0)
		this.getMap().removeInteraction(this.draw_modify)
		this.getMap().removeInteraction(this.draw)
	}

	remove() {
		this.btn_label.disabled = true
		if (this.draw_source) {
			if (this.draw_select && this.draw_select.getActive()) {
				this.draw_select.getFeatures().forEach(f => {
					this.draw_source.removeFeature(f)
				})

			} else {
				this.draw_source.clear()
			}
		}
	}

	active(btn, active) {
		// disable others
		document.querySelectorAll('button').forEach(b => {
			if (b != btn) {
				b.classList.remove('pressed')
			}
		})
		// enable/disable button clicked
		if (active) {
			btn.classList.add('pressed')
		} else {
			btn.classList.remove('pressed')
		}
	}

	add(btn, shape_type) {
		if (this.draw_shape_type == shape_type) {
			this.end()
			this.active(btn, false)
		} else {
			this.draw_shape_type = shape_type
			this.init()
			this.change(shape_type)
			this.active(btn, true)
		}
	}

	select() {
		this.btn_label.disabled = true
		this.end()
		let is_selecting = this.btn_select.classList.contains('pressed')
		this.active(this.btn_select, !is_selecting)
		const me = this
		if (this.draw) {
			if (!is_selecting) {
				if (!this.draw_select) {
					this.draw_select = new Select({
						layers: [this.draw_layer]
					})
					this.draw_select.on('select', function (event) {
						me.btn_label.disabled = event.selected.length == 0
					})
					this.getMap().addInteraction(this.draw_select)
				}
				this.draw_select.setActive(true)
				this.draw.setActive(false)
				this.draw_modify.setActive(false)
			} else {
				this.draw_select.getFeatures().clear()
				this.draw_select.setActive(false)
				this.draw.setActive(true)
				this.draw_modify.setActive(true)
			}
		}
	}

	label() {
		const drawPromptLabel = function (feature) {
			const default_label = feature.get('label') || ''
			const label = window.prompt(`Introduzca la etiqueta (${feature.getGeometry().getType()})`, default_label)
			return label || default_label
		}

		if (this.draw_select && this.draw_select.getActive()) {
			this.draw_select.getFeatures().forEach(f => {
				f.set('label', drawPromptLabel(f))
			})
			this.draw_source.changed()
			this.select()
		}
	}

	isSelecting() {
		return this.btn_select.classList.contains('pressed')
	}
}

/*
 * Custom control: search
 */
class SearchControl extends Control {
	/**
	 * @param {Object} [opt_options] Control options.
	 */
	constructor(opt_options) {

		const options = opt_options || {}

		const search_input_container = document.createElement('div')
		search_input_container.className = 'search-control-input-container'
		// search box
		const search_input = document.createElement('input')
		search_input.id = 'search-box'
		search_input.type = 'search'
		search_input.className = 'search-control-input'
		search_input.title = 'Introduzca texto de búsqueda o coordenadas separadas por espacio en blanco y ENTER'
		search_input.placeholder = 'Buscar...'
		search_input_container.appendChild(search_input)
		// search
		const search_search = document.createElement('input')
		search_search.type = 'button'
		search_search.value = '  '
		search_search.className = 'search-search'
		search_search.title = 'Buscar'
		search_input_container.appendChild(search_search)
		// clear
		const search_clear = document.createElement('input')
		search_clear.type = 'reset'
		search_clear.value = '  '
		search_clear.className = 'search-clear'
		search_clear.title = 'Eliminar búsquedas'
		search_input_container.appendChild(search_clear)

		// results window
		const search_nav = document.createElement('div')
		search_nav.id = 'search-navigation'
		search_nav.className = 'search-navigation'
		const nav_first = document.createElement('a')
		nav_first.id = 'search-first'
		nav_first.textContent = 'primera'
		search_nav.appendChild(nav_first)
		const nav_previous = document.createElement('a')
		nav_previous.id = 'search-previous'
		nav_previous.textContent = 'anterior'
		search_nav.appendChild(nav_previous)
		const nav_next = document.createElement('a')
		nav_next.id = 'search-next'
		nav_next.textContent = 'siguiente'
		search_nav.appendChild(nav_next)
		const nav_last = document.createElement('a')
		nav_last.id = 'search-last'
		nav_last.textContent = 'última'
		search_nav.appendChild(nav_last)
		const nav_close_container = document.createElement('div')
		nav_close_container.className = 'search-navigation-close-container'
		const nav_close = document.createElement('button')
		nav_close.className = 'search-navigation-close'
		nav_close_container.appendChild(nav_close)
		search_nav.appendChild(nav_close_container)

		const search_panel = document.createElement('div')
		search_panel.id = 'search-panel'
		search_panel.className = 'search-control-panel'

		const element = document.createElement('div')
		element.className = 'search-control ol-unselectable ol-control'
		element.appendChild(search_input_container)
		element.appendChild(search_nav)
		element.appendChild(search_panel)

		super({
			element: element,
			target: options.target,
		})

		this.search_input = search_input
		search_search.addEventListener('click', this.search.bind(this), false)
		search_input.addEventListener('keyup', this.search.bind(this), false)
		search_clear.addEventListener('click', this.clear.bind(this), false)

		nav_first.addEventListener('click', this.first.bind(this), false)
		nav_previous.addEventListener('click', this.previous.bind(this), false)
		nav_next.addEventListener('click', this.next.bind(this), false)
		nav_last.addEventListener('click', this.last.bind(this), false)
		nav_close.addEventListener('click', this.close.bind(this), false)
	}

	search_host = import.meta.env.VITE_SEARCH_BASE_URL

	search_layer_zIndex = import.meta.env.VITE_SEARCH_LAYER_ZINDEX

	search_zoom = {
		'topo': import.meta.env.VITE_SEARCH_TOPO_ZOOM,
		'coord': import.meta.env.VITE_SEARCH_COORD_ZOOM
	}

	first(event) {
		const url = $('#search-first').attr('url') || ''
		if (url != '') {
			this.search.bind(this)(event, $('#search-first').attr('url'))
		}
	}

	previous(event) {
		const url = $('#search-previous').attr('url') || ''
		if (url != '') {
			this.search.bind(this)(event, $('#search-previous').attr('url'))
		}
	}

	next(event) {
		const url = $('#search-next').attr('url') || ''
		if (url != '') {
			this.search.bind(this)(event, $('#search-next').attr('url'))
		}
	}

	last(event) {
		const url = $('#search-last').attr('url') || ''
		if (url) {
			this.search.bind(this)(event, url)
		}
	}

	close() {
		$('#search-panel').empty()
		$('#search-navigation').css('display', 'none')
		$('#search-panel').css('display', 'none')
	}

	loadTopo(id) {
		const url = this.search_host + `/busquedas/toponimiakml/1/50/${$('#search-box').val()}/1/${id}/`
		const vector = new VectorLayer({
			name: 'search-layer',
			zIndex: this.search_layer_zIndex,
			source: new VectorSource({
				url: url,
				format: new KML(),
			}),
		})
		const me = this
		vector.getSource().once('change', function () {
			me.getMap().getView().fit(this.getExtent(), { maxZoom: me.search_zoom['topo'] })
		})
		this.getMap().addLayer(vector)
	}

	loadPoint(coordinates) {
		const point = new Point(coordinates)
		const vector = new VectorLayer({
			name: 'search-layer',
			zIndex: this.search_layer_zIndex,
			source: new VectorSource({
				features: [new Feature(point)]
			}),
			style: {
				'circle-radius': 9,
				'circle-fill-color': 'red',
			}
		})
		const me = this
		this.getMap().addLayer(vector)
		this.getMap().getView().fit(vector.getSource().getExtent(), { maxZoom: me.search_zoom['coord'] })
	}

	load(entity) {
		if (typeof entity == 'string') {
			this.loadTopo(entity)
		} else {
			this.loadPoint(entity)
		}
	}

	clear() {
		this.getMap().getLayers().getArray().slice().forEach(item => {
			if (item.get('name') == 'search-layer') {
				this.getMap().removeLayer(item)
			}
		})
	}

	locateGeo(coordinates) {
		const utm_coordinates = transform(coordinates, 'EPSG:4326', 'EPSG:32628')
		this.locateUTM(utm_coordinates)
	}

	locateUTM(coordinates) {
		this.load(coordinates)
	}

	search(event, url) {
		if (event instanceof KeyboardEvent && event.key != 'Enter') {
			return
		}
		// toponimia o coordenadas?
		const coord_re = /^(-?((\d+)(\.\d+)?))\s+(-?((\d+)(\.\d+)?))$/i
		const m = this.search_input.value.match(coord_re)
		if (m) {
			const x_int = m[3]
			const y_int = m[7]
			const coord = [parseFloat(m[1]), parseFloat(m[5])]
			if (x_int.length < 3 && y_int.length < 3) {
				// coordenadas geográficas
				this.locateGeo(coord)
			} else {
				// coordenadas UTM
				this.locateUTM(coord)
			}
			return
		}
		$('#search-panel').empty()
		url = this.search_host + (url || `/busquedas/toponimoxmlgc/1/50/?texto=${this.search_input.value}`)
		$('.modal').show()
		const me = this
		$.ajax({
			url: url,
			method: 'GET',
			success: function (data) {
				// navigation
				const rows = data.querySelector('rows')
				const url_first = rows.attributes.getNamedItem('urlfirst').value
				const url_previous = rows.attributes.getNamedItem('urlprev').value
				const url_next = rows.attributes.getNamedItem('urlnext').value
				const url_last = rows.attributes.getNamedItem('urllast').value
				$('#search-first').attr('url', url_first)
				$('#search-previous').attr('url', url_previous)
				$('#search-next').attr('url', url_next)
				$('#search-last').attr('url', url_last)
				// items
				const page_total = parseInt(rows.attributes.getNamedItem('total').value)
				data.querySelectorAll('row').forEach((item) => {
					const container = $(`<div></div>`)
					const link = document.createElement('a')
					link.className = 'search-nombre'
					link.textContent = item.querySelector('nombre').textContent
					link.addEventListener('click', me.load.bind(me, item.querySelector('id').textContent))
					container.append(link)
					container.append(`<br><span class="search-descripcion">${item.querySelector('descripcion').textContent}</span>`)
					container.append(`<br><span class="search-clasificacion">${item.querySelector('clasificacion').textContent}</span>`)
					container.append(`<br><span class="search-localizacion">${item.querySelector('localizacion').textContent}</span>`)
					$('#search-panel').append(container)
				})
				$('#search-navigation').css('display', page_total > 1 ? 'block' : 'none')
				$('#search-panel').css('display', page_total > 0 ? 'block' : 'none')
			},
			error: function (xhr, textStatus, errorThrown) {
				console.log(textStatus)
			},
			complete: function () {
				$('.modal').hide()
			}
		})
	}

}

export { DrawingControl, SearchControl }