import { Map, View, Overlay } from 'ol';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4.js';
import { transform } from 'ol/proj';
import MousePosition from 'ol/control/MousePosition.js';
import ZoomControl from 'ol/control/Zoom.js';
import { ScaleLine, Zoom, defaults as defaultControls } from 'ol/control.js';
import { Fill, Stroke, Style } from 'ol/style.js';
import GeoJSON from 'ol/format/GeoJSON.js';

import { complexCode, renderZoneData } from './ui'

import { DrawingControl as DrawControl, SearchControl } from './ol-controls'

let map, map_back
const zone_vector_name = 'zone'
const zone_zIndex = 1
const style_zIndex = 99999


/*
 * Data
 */
const zone_style_url = import.meta.env.VITE_ZONE_STYLE_URL
let zone_style = {}

function loadStyle() {
  const promise = new Promise((resolve, reject) => {
    fetch(zone_style_url)
    .then(response => response.json())
    .then(json => {
      resolve(json)
    })
  })
  return promise
}


/*
 * Controls
 */
const utm_precision = import.meta.env.VITE_UTM_PRECISION

// mouse position
const mousePositionControl = new MousePosition({
  coordinateFormat: (coord) => {
    const t = transform(coord, map.getView().getProjection(), 'EPSG:32628')
    return 'x: ' + t[0].toLocaleString('es', { maximumFractionDigits: utm_precision })
        + ' y: ' + t[1].toLocaleString('es', { maximumFractionDigits: utm_precision })
  }
});

// scale
const scaleControl = new ScaleLine();

/*
 * Styles
 */
const styleDefault = {
  unselect: {
    fill: new Fill({
      color: 'rgba(225, 225, 225, 0)'
    }),
    stroke: new Stroke({
      color: 'rgba(225, 225, 225, 0)'
    })
  },
  select: {
    fill: new Fill({
      color: 'rgba(225, 225, 225, 0.8)',
    }),
    stroke: new Stroke({
      color: 'cyan',
      width: 2,
    })
  }
}

const style = new Style({
  fill: styleDefault.unselect.fill,
  stroke: styleDefault.unselect.stroke
});

/*
 * Layers
 */
function getLayerByName(name) {
  return map.getLayers().getArray().find((item) => {
    return item.get('name') === name
  })
}

const orto = new TileLayer({
  name: 'orto',
  source: new TileWMS({
    // url: 'https://idecan1.grafcan.es/ServicioWMS/OrtoExpress',
    // params: { LAYERS: 'ortoexpress', FORMAT: 'image/jpeg', TILED: true },
    url: 'https://idecan3.grafcan.es/ServicioWMS/OrtoExpress_bat',
    params: { LAYERS: 'ortoexpress', FORMAT: 'image/jpeg' },
    crossOrigin: 'anonymous'
  })
})

const orto_back = new TileLayer({
  name: 'orto',
  source: new TileWMS({
    // url: 'https://idecan1.grafcan.es/ServicioWMS/OrtoExpress',
    // params: { LAYERS: 'ortoexpress', FORMAT: 'image/jpeg', TILED: true },
    url: 'https://idecan3.grafcan.es/ServicioWMS/OrtoExpress_bat',
    params: { LAYERS: 'ortoexpress', FORMAT: 'image/jpeg' },
    crossOrigin: 'anonymous'
  })
})

function styleFunction(feature) {
  const zoneCode = feature.get('zona')
  let strokeStyle = styleDefault.unselect.stroke
  let fillStyle = styleDefault.unselect.fill
  if (
    zone_style[complexCode]
    && zone_style[complexCode][zoneCode]
  ) {
    const symb = zone_style[complexCode][zoneCode]
    if (symb.stroke) {
      strokeStyle = new Stroke({
        color: symb.stroke,
        width: symb['stroke-width']
      })
    }
    if (symb.fill) {
      fillStyle = new Fill({
        color: symb.fill
      })
    }
  }
  return new Style({
    fill: fillStyle,
    stroke: strokeStyle
  })
}

function refreshZones(url) {
  const cur_vector = getLayerByName(zone_vector_name)
  if (cur_vector && cur_vector.getSource().getUrl() == url) {
    cur_vector.getSource().refresh()
  } else {
    map.removeLayer(cur_vector)
    if (url) {
      const vector = new VectorLayer({
        name: zone_vector_name,
        source: new VectorSource({
          url: url,
          format: new GeoJSON(),
        }),
        style: styleFunction,
        zIndex: zone_zIndex
      })
      map.addLayer(vector)
    }
  }
}

function getExtent() {
  return map.getView().calculateExtent()
}

function getImageMap(callback) {
  const mapCanvas = map_back.getViewport().querySelector('.ol-layer canvas, canvas.ol-layer')
  callback(mapCanvas.toDataURL())
}

// over feature
function featureInfo(f) {
  renderZoneData(f.get('zona'))
}

/*
 * Events
 */
let selected_zone = null;

function clearSelected() {
  if (selected_zone !== null) {
    selected_zone.setStyle(undefined);
    selected_zone = null;
  }
}

function init(crs='4326') {
  // load data
  loadStyle()
  .then(json => {

    // zone styles
    zone_style = json

    /*
     * Initialize map
     */
    proj4.defs("EPSG:32628", "+proj=utm +zone=28 +datum=WGS84 +units=m +no_defs +type=crs")
    register(proj4)

    let projection, extent, center
    switch (crs) {
      case '32628':
        projection = 'EPSG:32628'
        extent = [387891.2179428498, 3062302.499071488, 500515.97461822355, 3122772.856119615]
        center = [441503.5962805367, 3092537.6775955516]
        break
      default:
        projection = 'EPSG:4326'
        extent = [-16.1368341, 27.6802766, -14.994741, 28.2308284]
        center = [-15.5972517, 27.9567213]
    }

    // map controls
    const drawControl = new DrawControl()

    // zoom buttons
    const zoomInElement = document.createElement('img')
    zoomInElement.src = '/img/zoomin.png'
    const zoomOutElement = document.createElement('img')
    zoomOutElement.src = '/img/zoomout.png'

    // map
    map = new Map({
      target: 'map',
      layers: [ orto ],
      controls: defaultControls().extend([
        mousePositionControl,
        scaleControl,
        drawControl,
        new SearchControl(),
        new ZoomControl({
          zoomInLabel: zoomInElement,
          zoomOutLabel: zoomOutElement
        })
      ]),
      view: new View({
        projection: projection,
        extent: extent,
        center: center,
        zoom: 11
      })
    })

    function formatCoordinate(coordinate) {
      return `
        <table>
          <tbody>
            <tr><th>x:</th><td>${coordinate[0].toFixed(2)}</td></tr>
            <tr><th>y:</th><td>${coordinate[1].toFixed(2)}</td></tr>
          </tbody>
        </table>`
    }

    function showInfo(feature) {
      switch (feature.getGeometry().getType()) {
        case 'Point':
          return formatCoordinate(feature.getGeometry().getCoordinates())
        default:
          // const info = feature.get('description') || `Zona ${feature.get('zona')}`
          const info = feature.get('description')
          return info
      }
    }

    // map events
    map.getLayers().on('add', function(e) {
      map_back.addLayer(e.element)
    })
    map.getLayers().on('remove', function(e) {
      map_back.removeLayer(e.element)
    })
  
    // info
    let popover
    const popover_close_delay = 10000
    const popover_element = document.getElementById('popup')
    popover_element.addEventListener('shown.bs.popover', function () {
      setTimeout(() => { if (popover) { popover.hide() } }, popover_close_delay)
    })
    const popup = new Overlay({
      element: popover_element,
      stopEvent: false,
    })
    map.addOverlay(popup)

    // info click
    map.on('click', function (event) {
      if (popover) {
        popover.dispose()
        popover = undefined
      }

      // only searched features
      const options = {
        layerFilter: function(layer) {
          return layer.get('name') == 'search-layer'
        }
      }
      const feature = map.getFeaturesAtPixel(event.pixel, options)[0];
      if (!feature) {
        return
      }
      const feature_type = feature.getGeometry().getType()
      popup.setPosition(map.getCoordinateFromPixel(event.pixel))
    
      popover = new bootstrap.Popover(popover_element, {
        container: popover_element.parentElement,
        content: showInfo(feature),
        title: feature.get('name') || 'Coordenadas',
        html: true,
        placement: 'top',
        sanitize: false,
        customClass: feature_type == 'Point' ? 'popup-point' : 'popup-topo'
      })
      popover.show()
    })
    
    // zone selection
    map.on('singleclick', function (e) {
      if (drawControl.isSelecting()) {
        return
      }
      clearSelected();
      map.forEachFeatureAtPixel(e.pixel, function (f) {
        selected_zone = f
        // style
        const style = styleFunction(f)
        f.setStyle(new Style({
          fill: style.getFill(),
          stroke: new Stroke({
            color: styleDefault.select.stroke.getColor(),
            width: styleDefault.select.stroke.getWidth()
          }),
          zIndex: style_zIndex
        }))
        // show info
        featureInfo(f)
        // return true
      },
      {
        layerFilter: function(layer) {
          return layer.get('name') == zone_vector_name
        }
      })
    })

    // map view events
    map.getView().on('change:center', (e) => {
      map_back.getView().setCenter(e.target.get(e.key))
    })

    map.getView().on('change:resolution', (e) => {
      map_back.getView().setResolution(e.target.get(e.key))
    })

    // background map (printing)
    map_back = new Map({
      target: 'map_back',
      layers: [
        new orto.constructor({
          source: orto.getSource(),
          opacity: orto.getOpacity(),
          extent: orto.getExtent(),
          zIndex: orto.getZIndex()
        })
      ],
      view: new View({
        projection: projection,
        extent: extent,
        center: center,
        zoom: 11
      })
    })

  })

}

export {
  init, refreshZones, getImageMap, getExtent
}